import dayjs from 'dayjs';
import { isoDateRegex } from 'lib/utils/isoDateRegex';

const parseZone = (date, ...args) => {
  if (typeof date === 'string') {
    const match = date.match(isoDateRegex);
    if (match !== null) {
      const [, dateTime] = match;
      return dayjs(dateTime, {
        args,
      });
    }
    return dayjs(date, {
      args,
    });
  }
  throw new Error('Invalid Date');
};

export default parseZone;
