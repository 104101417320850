import React from 'react';
import StyledBox from 'views/common/components/UI/MobileProgressBar/MobileProgressBar.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';

const MobileProgressBar = (props) => {
  const {
    currentStep, stepName, totalSteps, prevPath,
  } = props;
  return (
    <StyledBox>
      <Typography500 component="p" variant="body1" className="MBBLable">
        {currentStep != 1 && (
          <NavigatorLink href={prevPath}>
            <ChevronLeftIcon className="MBBBack" />
          </NavigatorLink>
        )}
        {currentStep}
        .
        {stepName}
      </Typography500>
      <Typography500 component="p" variant="body1" className="MBBNumber">
        {currentStep}
        /
        {totalSteps}
      </Typography500>
    </StyledBox>
  );
};

export { MobileProgressBar };
