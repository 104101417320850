import React from 'react';
import { Checkbox, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme, error }) => ({
  '& .root': {
    borderLeft: `5px solid ${
      error ? theme.palette.error.light : theme.palette.primary.light
    }`,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  '& .error': {
    color: theme.palette.error.main,
  },
  '& .ack': {
    display: 'flex',
    cursor: 'pointer',
  },
  '& .checkbox': {
    padding: theme.spacing(0),
    paddingRight: theme.spacing(1),
    color: error ? theme.palette.error.light : theme.palette.grey[300],
  },
}));
const Acknowledgement = (props) => {
  const {
    label, isChecked, onChange = () => {}, error, styles = {}, className,
  } = props;
  return (
    <StyledRoot className={className} error={error}>
      <Box className="root">
        <Box
          className="ack"
          styles={styles.ack}
          onClick={() => onChange(!isChecked)}>
          <Box>
            <Checkbox
              className="checkbox"
              checked={isChecked}
              styles={styles.ack}
              color="primary"
              onChange={() => onChange(!isChecked)}
            />
          </Box>
          <Box>{label}</Box>
        </Box>
      </Box>
      {error && (
        <Typography className="error" styles={styles.error}>
          {error}
        </Typography>
      )}
    </StyledRoot>
  );
};

export { Acknowledgement };
