import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconVerified = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IconVerified;
