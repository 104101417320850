import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  textTransform: 'uppercase',
  justifyContent: 'space-between',
  '& .MBBLable': {
    color: theme.palette.grey[900],
    display: 'flex',
  },
  '& .MBBNumber': {
    color: theme.palette.common.black,
  },
  '& .MBBBack': {
    borderRadius: '50%',
    color: theme.palette.grey[900],
    backgroundColor: theme.palette.grey[50],
    marginRight: theme.spacing(1.4),
  },
}));

export default StyledBox;
