import mapStepToUrl from 'views/reservation-path-module/common/utils/mapStepToUrl';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/features/ui-slice';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import useFlashMsg from 'views/common/hooks/useFlashMsg';
import { notifyAirbrake } from 'lib/config/airbrake';
import PATHS from 'lib/navigation/paths';
import prepareRentalWidgetQueryParams from 'views/reservation-path-module/common/utils/prepareRentalWidgetQueryParams';

const use403ResponseHandler = (errorCodes = {}, useCommonErrorCodes = true) => {
  const appNavigator = useAppNavigator();
  const sendFlashMsg = useFlashMsg();
  const dispatch = useDispatch();

  const handleRedirectByResource = (error) => {
    const queryParams = error.widget_options && prepareRentalWidgetQueryParams(error);
    const { type, slug, id } = error.resource || {};
    switch (type) {
      case 'deal':
        return PATHS.motorcycleDeals.slug(`${slug}-${id}`, queryParams);
      case 'self_drive_tour':
        return PATHS.selfDriveTour(slug, queryParams);
      case 'guided_tour':
        return PATHS.guidedTour(slug, queryParams);
      default:
        return PATHS.home(queryParams);
    }
  };

  const getReservationEndpointsActions = (error) => ({
    ER0001: {
      path: PATHS.reservationPath.complete(),
    },
    ER0002: {
      path: handleRedirectByResource(error.response.data),
    },
    ER0003: {
      path: handleRedirectByResource(error.response.data),
    },
    ER0004: { path: PATHS.reservationPath.riders(), skipSendingFlashMsg: true },
    ER0005: { path: mapStepToUrl[error.response.data.step] },
    ER0006: { path: PATHS.reservationPath.extras() },
    ER0007: { path: handleRedirectByResource(error.response.data) },
    ER0008: { path: handleRedirectByResource(error.response.data) },
    ER0014: { path: handleRedirectByResource(error.response.data) },
    ER0015: { path: handleRedirectByResource(error.response.data) },
    ...errorCodes,
  });

  const getPaymentEndpointsActions = (error) => ({
    ER0001: {
      path: handleRedirectByResource(error.response.data),
    },
    ER0002: {
      path: handleRedirectByResource(error.response.data),
    },
    ER0007: { path: handleRedirectByResource(error.response.data) },
    ER0008: {
      path: handleRedirectByResource(error.response.data),
    },
    ...errorCodes,
  });

  const handle403Response = (error, isPaymentEndpoint = true) => {
    let mapErrorCodeToAction;
    if (useCommonErrorCodes) {
      mapErrorCodeToAction = isPaymentEndpoint
        ? getPaymentEndpointsActions(error)
        : getReservationEndpointsActions(error);
    } else {
      mapErrorCodeToAction = errorCodes;
    }
    if (error.response?.data?.error_code in mapErrorCodeToAction) {
      if (
        mapErrorCodeToAction[error.response?.data?.error_code]
          .skipSendingFlashMsg
      ) {
        dispatch(
          setSnackbar({
            open: true,
            severity: 'error',
            message:
              error.response?.data?.error_message
              || 'Something is wrong with your authorization to access this page',
          }),
        );
        if (mapErrorCodeToAction[error.response?.data?.error_code].path) {
          appNavigator.push(
            mapErrorCodeToAction[error.response?.data?.error_code].path,
          );
        }
      } else {
        sendFlashMsg(
          mapErrorCodeToAction[error.response?.data?.error_code].msg
            || error.response?.data?.error_message
            || '',
          'error',
        )
          .catch((err) => {
            notifyAirbrake(`Error setting flash message : ${err}`);
          })
          .finally(() => {
            if (mapErrorCodeToAction[error.response?.data?.error_code]?.path) {
              appNavigator.push(
                mapErrorCodeToAction[error.response?.data?.error_code].path,
              );
            }
          });
      }
    } else {
      dispatch(
        setSnackbar({
          open: true,
          severity: 'error',
          message:
            error.response?.data?.error_message
            || 'Something is wrong with your authorization to access this page',
        }),
      );
    }
  };
  return handle403Response;
};

export default use403ResponseHandler;
