/* eslint-disable camelcase */
import axios, { API } from 'lib/config/axios';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/features/ui-slice';

const useFlashMsg = () => {
  const appState = useSelector((state) => state.globalData.appState);
  const { authenticity_token } = appState;
  const dispatch = useDispatch();

  const sendFlashMsg = (message, type) => {
    dispatch(
      setSnackbar({
        open: true,
        severity: type,
        message,
      }),
    );
    return axios.post(API.flashMessage, {
      type,
      message,
      authenticity_token,
    });
  };
  return sendFlashMsg;
};

export default useFlashMsg;
