import React from 'react';
import { Box, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.root': {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    position: 'relative',
    zIndex: '0',
  },
  '& .step': {
    color: theme.palette.grey['300'],
    position: 'relative',
    flex: '1',
    display: 'flex',
    justifyContent: 'center',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '11px',
      left: theme.spacing(1),
      right: theme.spacing(-1),
      height: '1px',
      backgroundColor: theme.palette.grey['300'],
    },

    '&.stepDone': {
      '& .stepIcon': {
        borderColor: theme.palette.primary.dark,
      },
      '&::before': {
        backgroundColor: theme.palette.primary.main,
      },
    },

    '&.stepActive, &.stepDone': {
      color: theme.palette.primary.dark,
    },

    '&:first-child': {
      justifyContent: 'flex-start',
    },

    '&:first-child $stepInner': {
      '&::before': {
        display: 'block',
        left: '0',
        right: '50%',
      },
    },

    '&:last-child': {
      justifyContent: 'flex-end',
    },

    '&:last-child $stepInner': {
      '&::before': {
        display: 'block',
        left: '50%',
        right: '0',
      },
    },
  },
  '& .stepInner': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '18px',
      height: '5px',
      backgroundColor: '#fafafa',
      display: 'none',
    },
  },
  '& .stepIcon': {
    backgroundColor: '#fafafa',
    border: `1px solid ${theme.palette.grey['200']}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
    marginBottom: theme.spacing(0.5),
    position: 'relative',
    zIndex: '2',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 0,
    '.stepActive &, .stepDone &': {
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  '& .stepDoneIcon': {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '50%',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '.875rem',
      height: '.875rem',
    },
  },
}));

const HorizontalStepperSmall = (props) => {
  const {
    activeStepNo, steps, stepStyle, className,
  } = props;

  return (
    <StyledBox className={`root ${className}`}>
      {steps.map((step, stepIndex) => (
        <Box
          className={`step ${
            stepIndex + 1 == activeStepNo ? 'stepActive' : ''
          } ${stepIndex + 1 < activeStepNo ? 'stepDone' : ''} ${stepStyle}`}
          key={step.label}>
          <Box className="stepInner">
            <Box className="stepIcon">
              {step.icon}

              {stepIndex + 1 < activeStepNo && (
                <Box className="stepDoneIcon">
                  <CheckIcon />
                </Box>
              )}
            </Box>
            <Box className="stepLabel">{step.label}</Box>
          </Box>
        </Box>
      ))}
    </StyledBox>
  );
};

export { HorizontalStepperSmall };
