import React from 'react';
import StyledBox from 'views/common/components/UI/VRDetails/VRDetails.styles';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import { Typography, Box, Stack } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Typography700 } from 'views/common/components';
import parseZone from 'lib/utils/parseZone';
import { localeDateFormatMap } from 'lib/config/date-picker';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import addressFormat from 'lib/utils/address_format';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const VRDetails = (props) => {
  const { t } = useTranslation('fe_er_common_vr_details');
  const {
    reservationData,
    getDirections = false,
    emailPhone = false,
    className,
    tourReservation = false,
  } = props;
  const appNavigator = useAppNavigator();
  return (
    <StyledBox className={className}>
      <Typography500 variant="h3" component="p" className="VRDTitle">
        {tourReservation
          ? t('fe_er_common_vr_details:tour_reservation_title')
          : t('fe_er_common_vr_details:reservation_title')}
      </Typography500>
      <Typography500 variant="h6" component="p" className="VRDBikeAndRide">
        <Typography500 variant="h6" component="span" pr={2}>
          {reservationData.ridersCount}
          {' '}
          {t('fe_er_common_vr_details:bike')}
        </Typography500>
        <Typography500 variant="h6" component="span">
          {reservationData.rental_days_count}
          {' '}
          {t('fe_er_common_vr_details:rental_days')}
        </Typography500>
      </Typography500>
      <Box className="VRDPickItemAndDropOff">
        <Box className="VRDPickupItemWrapper">
          {tourReservation && (
            <Box className="VRDPickItem VRDTourStartDate" mt={3}>
              <Typography500
                variant="h6"
                component="p"
                className="VRDItemTitle">
                {t('fe_er_common_vr_details:tour_start_date')}
              </Typography500>
              <Box className="VRDInfoItem" mt={1} pb={2}>
                <CalendarTodayIcon className="VRDInfoIcon" />
                {reservationData?.tour?.start_time && (
                  <Typography700
                    variant="h5"
                    component="p"
                    className="VRDInfoText">
                    {parseZone(reservationData.tour.start_time).format(
                      `${localeDateFormatMap[
                        appNavigator.locale
                      ].toUpperCase()} hh:mm A`,
                    )}
                  </Typography700>
                )}
              </Box>
            </Box>
          )}
          <Box className="VRDPickItem">
            <Box>
              <Typography500
                variant="h6"
                component="p"
                className="VRDItemTitle">
                {t('fe_er_common_vr_details:pick_up')}
              </Typography500>

              <Box className="VRDInfoItem" my={1}>
                <CalendarTodayIcon className="VRDInfoIcon" />
                <Typography700
                  variant="h5"
                  component="p"
                  className="VRDInfoText">
                  {parseZone(reservationData.pickup_time).format(
                    `${localeDateFormatMap[
                      appNavigator.locale
                    ].toUpperCase()} hh:mm A`,
                  )}
                </Typography700>
              </Box>
            </Box>

            <Box className="VRDInfoItem">
              <LocationOnIcon className="VRDInfoIcon VRDLocationIcon" />
              <Box component="span" mb={getDirections && 2}>
                <Typography
                  variant="body2"
                  component="p"
                  className="VRDInfoText"
                  mb={2}>
                  <Typography
                    variant="body2"
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: t('fe_er_common_vr_details:eagleRider_location', {
                        location: reservationData.pickup_location.display_name,
                      }),
                    }}
                  />
                  {typeof reservationData.pickup_location.address === 'string'
                    ? reservationData.pickup_location.address
                    : addressFormat({
                      country:
                          reservationData.pickup_location.address.country,
                      region: reservationData.pickup_location.address.region,
                      postalCode:
                          reservationData.pickup_location.address.postal_code,
                      streetAddress1:
                          reservationData.pickup_location.address.address1,
                      streetAddress2:
                          reservationData.pickup_location.address.address2,
                    })}
                </Typography>
                {getDirections && (
                  <NavigatorLink
                    mapUrl={reservationData.pickup_location.map_url}>
                    {t('fe_er_common_vr_details:get_directions')}
                  </NavigatorLink>
                )}
                {emailPhone && (
                  <Stack spacing={2} direction="row" className="VRDEmailPhone">
                    {reservationData.pickup_location.email && (
                      <a
                        href={`mailto:${reservationData.pickup_location.email}`}
                        className="VRDContactLink">
                        <EmailIcon className="VRDContactIcon" />
                        {reservationData.pickup_location.email}
                      </a>
                    )}
                    {reservationData.pickup_location.phone && (
                      <a
                        href={`tel:${reservationData.pickup_location.phone}`}
                        className="VRDContactLink">
                        <PhoneIcon className="VRDContactIcon" />
                        {reservationData.pickup_location.phone}
                      </a>
                    )}
                  </Stack>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="VRDDropOffItem">
          <Box>
            <Typography500 variant="h6" component="p" className="VRDItemTitle">
              {t('fe_er_common_vr_details:drop_off')}
            </Typography500>
            <Box className="VRDInfoItem" my={1}>
              <CalendarTodayIcon className="VRDInfoIcon" />
              <Typography700 variant="h5" component="p" className="VRDInfoText">
                {parseZone(reservationData.dropoff_time).format(
                  `${localeDateFormatMap[
                    appNavigator.locale
                  ].toUpperCase()} hh:mm A`,
                )}
              </Typography700>
            </Box>
          </Box>

          <Box className="VRDInfoItem">
            <LocationOnIcon className="VRDInfoIcon VRDLocationIcon" />

            <Box component="span" mb={getDirections && 2}>
              <Typography
                variant="body2"
                component="p"
                className="VRDInfoText"
                mb={2}>
                <Typography
                  variant="body2"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: t('fe_er_common_vr_details:eagleRider_location', {
                      location: reservationData.dropoff_location.display_name,
                    }),
                  }}
                />
                {typeof reservationData.dropoff_location.address === 'string'
                  ? reservationData.dropoff_location.address
                  : addressFormat({
                    country: reservationData.dropoff_location.address.country,
                    region: reservationData.dropoff_location.address.region,
                    postalCode:
                        reservationData.dropoff_location.address.postal_code,
                    streetAddress1:
                        reservationData.dropoff_location.address.address1,
                    streetAddress2:
                        reservationData.dropoff_location.address.address2,
                  })}
              </Typography>
              {getDirections && (
                <NavigatorLink
                  mapUrl={reservationData.dropoff_location.map_url}>
                  {t('fe_er_common_vr_details:get_directions')}
                </NavigatorLink>
              )}
              {emailPhone && (
                <Stack spacing={2} direction="row" className="VRDEmailPhone">
                  {reservationData.dropoff_location.email && (
                    <a
                      href={`mailto:${reservationData.dropoff_location.email}`}
                      className="VRDContactLink">
                      <EmailIcon className="VRDContactIcon" />
                      {reservationData.dropoff_location.email}
                    </a>
                  )}
                  {reservationData.dropoff_location.phone && (
                    <a
                      href={`tel:${reservationData.dropoff_location.phone}`}
                      className="VRDContactLink">
                      <PhoneIcon className="VRDContactIcon" />
                      {reservationData.dropoff_location.phone}
                    </a>
                  )}
                </Stack>
              )}
            </Box>
          </Box>
          {tourReservation && (
            <Box className="VRDEndTourItem VRDTourEndDate">
              <Typography500
                variant="h6"
                component="p"
                className="VRDItemTitle">
                {t('fe_er_common_vr_details:tour_end_date')}
              </Typography500>

              <Box className="VRDInfoItem" mt={1}>
                <CalendarTodayIcon className="VRDInfoIcon" />

                <Typography700
                  variant="h5"
                  component="p"
                  className="VRDInfoText">
                  {parseZone(
                    reservationData.tour.end_time
                      || reservationData.dropoff_time,
                  ).format(
                    `${localeDateFormatMap[
                      appNavigator.locale
                    ].toUpperCase()} hh:mm A`,
                  )}
                </Typography700>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </StyledBox>
  );
};

export { VRDetails };
