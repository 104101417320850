import PATHS from 'lib/navigation/paths';

const mapStepToUrl = {
  root: PATHS.home(),
  'rider-name': PATHS.reservationPath.riders(),
  extras: PATHS.reservationPath.extras(),
  checkout: PATHS.reservationPath.checkout(),
  motorcycles: PATHS.reservationPath.vehicles(),
  confirmation: PATHS.reservationPath.complete(),
};

export default mapStepToUrl;
