import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .VRDTitle, & .VRDBikeAndRide': {
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.dark,
  },

  '& .VRDInfoItem': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  '& .VRDCopyBtn': {
    padding: theme.spacing(0, 1),
  },
  '& .VRDCopyIcon': {
    fontSize: theme.spacing(2),
    position: 'relative',
    top: theme.typography.pxToRem(-1),
  },
  '& .VRDInfoIcon': {
    fontSize: theme.spacing(2.5),
    color: theme.palette.secondary.light,
    '&.VRDLocationIcon': {
      fontSize: theme.spacing(2.75),
    },
  },
  '& .VRDInfoText, & .VRDItemTitle': {
    color: theme.palette.secondary.main,
    whiteSpace: 'wrap',
  },

  '& .VRDPickItem': {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      borderBottom: 0,
    },
  },
  '& .VRDContactLink': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '& .VRDContactIcon': {
      fontSize: theme.typography.pxToRem(20),
      marginRight: theme.spacing(0.5),
    },
  },
  '& .VRDEndTourItem': {
    paddingTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  '& .VRDPickItemAndDropOff': {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
    '& .VRDPickupItemWrapper': {
      [theme.breakpoints.up('lg')]: {
        marginRight: theme.spacing(3),
        boxShadow: 'unset',
        width: '50%',
        marginBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        '& .VRDTourStartDate': {
          marginTop: 0,
          '& .VRDInfoItem': {
            marginTop: 0,
          },
        },
      },
    },
    '& .VRDDropOffItem': {
      [theme.breakpoints.up('lg')]: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
      },
      '& .VRDTourEndDate': {
        [theme.breakpoints.up('lg')]: {
          order: -1,
          borderTop: 0,
          paddingTop: 0,
          marginBottom: theme.spacing(3),
          '& .VRDInfoItem': {
            marginTop: 0,
          },
        },
      },
    },
    '& .VRDEmailPhone': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
  },
}));

export default StyledBox;
